<template>
    <div class="Logo">
        <router-link :to="{ name: 'Home' }" title="">
            <img :src="Logo" :srcset="LogoRetina" alt=""/>
        </router-link>
    </div>
</template>

<script>

export default {
    name: "Logo",
    data(){
        return {
            Logo: process.env.VUE_APP_LOGO,
            LogoRetina: process.env.VUE_APP_LOGO_RETINA
        }        
    }
}
</script>