import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

class ProductsService{
    getFeaturedProducts( token, shop = "b2b", codiceSquadra ){     
        return axios({
            method: 'get',
            url: API_URL + shop +'/Articoli/GetArticoliByCategoria',
            params: { idCategoria: "KIT", codSocieta: codiceSquadra },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  { 
                if(data.status == 401){
                    return null;
                }
                else{
                    return  data.data.Data
                    /*.filter((Produts) => {
                        return Produts.CodiceArticolo.toLowerCase().includes("." + codiceSquadra.padStart(4, '0') + ".");
                    })*/
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getAbbigliamento(token, shop = "b2b"){
        return axios({
            method: 'get',
            url: API_URL + shop +'/Articoli/GetArticoliByCategoria',
            params: { idCategoria: "ABBIGLIAMENTO" },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getArticoli(token, shop = "b2b", category, codiceSquadra ){
        return axios({
            method: 'get',
            url: API_URL + shop +'/Articoli/GetArticoliByCategoria',
            params: { idCategoria: category,codSocieta: codiceSquadra },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getArticoliGestImg(shop = "b2b", category, codiceSquadra ){
        return axios({
            method: 'get',
            url: API_URL + shop +'/GestoreImmagini/GetArticoliByCategoria',
            params: { idCategoria: category,codSocieta: codiceSquadra },
            headers:{
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getArticoliGestPrezziTaglie(shop = "b2b", category, codiceSquadra ){
        return axios({
            method: 'get',
            url: API_URL + shop +'/GestoreTagliePrezzi/GetArticoliByCategoria',
            params: { idCategoria: category,codSocieta: codiceSquadra },
            headers:{
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getArticolo(token, shop = "b2b", Category, ProductId, CodiceSocieta ){
        return axios({
            method: 'get',
            url: API_URL + shop +'/Articoli/GetArticoloById',
            params: { 
                idArticolo: ProductId,
                codCliente: CodiceSocieta
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getPrezzoArticolo(token, shop = "b2b", CustomerId, ProductId, codiceDestinazione ){
        return axios({
            method: 'get',
            url: API_URL + 'b2c/Listini/GetListiniDestinazione',
            params: { 
                codArticolo: ProductId,
                codCliente: CustomerId,
                codDestinazione: codiceDestinazione
            },
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getCategorie(token, shop = "b2b", father){
        return axios({
            method: 'get',
            url: API_URL + shop +'/Articoli/GetAlberoCategorie',
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {return error;});            
    }
}

export default new ProductsService();